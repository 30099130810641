import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import { MdOutlineSchool, MdOutlineChecklist } from "react-icons/md";

function Skills() {
  return (
    <>
      <div className="section text-center landing-section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="title">Education and Skills</h2>
              <h5>
                This is the paragraph where you can write more details about
                your product. Keep you user engaged by providing meaningful
                information. Remember that by this time, the user is curious,
                otherwise he wouldn't scroll to get here. Add a button if you
                want the user to see more.
              </h5>
              <br />
              <Button
                className="btn-fill btn-round"
                color="danger"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                See Details
              </Button>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col md="5">
              <div className="info">
                <div className="icon icon-danger">
                  {/* <i className="nc-icon nc-palette" /> */}
                  <MdOutlineSchool />
                </div>
                <div className="description">
                  <h4 className="info-title">Education</h4>
                  <p className="description">
                    <ul>
                      <li>
                        University of Texas Minimally Invasive and Bariatric
                        Surgery Fellowship
                      </li>
                      <li>
                        Philadelphia College of Osteopathic Medicine General
                        Surgery Residency
                      </li>
                      <li>
                        Philadelphia College of Osteopathic Medicine Medical
                        School
                      </li>
                      <li>University of North Carolina - Chapel Hill</li>
                    </ul>
                  </p>
                  <Button
                    className="btn-link"
                    color="danger"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    See more
                  </Button>
                </div>
              </div>
            </Col>
            <Col md="5">
              <div className="info">
                <div className="icon icon-danger">
                  <MdOutlineChecklist />
                </div>
                <div className="description">
                  <h4 className="info-title">New Ideas</h4>
                  <p>
                    Larger, yet dramatically thinner. More powerful, but
                    remarkably power efficient.
                  </p>
                  <Button
                    className="btn-link"
                    color="danger"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    See more
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Skills;
