import React from "react";

// core components
// import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
import MainNavbar from "components/Navbars/MainNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import FooterWhite from "components/Footers/FooterWhite.js";
import Skills from "components/LandingComponents/Skills.js";
import Testimonials from "components/LandingComponents/Testimonials";
import Interested from "components/LandingComponents/Interested";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      {/* <MultiDropdownNavbar /> */}
      <MainNavbar />
      <LandingPageHeader />
      <div className="wrapper">
        <Skills />
        <Testimonials />
        <Interested />
      </div>
      <FooterWhite />
    </>
  );
}

export default LandingPage;
