import React from "react";
import { Button, Container, Row, Col, Input, Form } from "reactstrap";

function Interested() {
  return (
    <>
      <div className="section landing-section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="text-center">Interested in an appointment?</h2>
              <Form className="contact-form">
                <Row>
                  <Col md="6">
                    <label>Name</label>
                    <Input placeholder="Name" />
                  </Col>
                  <Col md="6">
                    <label>Email</label>
                    <Input placeholder="Email" />
                  </Col>
                </Row>
                <label>Message</label>
                <Input
                  placeholder="Tell us your thoughts and feelings..."
                  type="textarea"
                  rows="4"
                />
                <Row>
                  <Col className="offset-md-4" md="4">
                    <Button className="btn-fill" color="danger" size="lg">
                      Send Message
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Interested;
