import { Component } from "react";
import ReactPlayer from "react-player";
import { Button } from "reactstrap";

class IntroPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isClicked: false,
    };
  }

  handleYouTubeClick = () => {
    this.setState({
      isClicked: !this.state.isClicked,
    });
  };

  render() {
    return (
      <div>
        <Button
          className="btn-round mr-1"
          color="neutral"
          onClick={this.handleYouTubeClick}
        >
          <i className="fa fa-play" />
          Watch Intro
        </Button>
        {this.state.isClicked && (
          <div>
            <ReactPlayer url="https://youtu.be/OXHCt8Ym9gw" />
          </div>
        )}
      </div>
    );
  }
}
export default IntroPlayer;
